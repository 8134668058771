import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, Link, Icon, Counter } from "./navComponents";

import AppContext from "../../context/AppContext";
import { invited, requested, team } from "../../config/statusGroups";

import moment from "moment";

const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

const QuickLinks = ({ canViewRedemptions }) => {
    return (
        <AppContext.Consumer>
            {({ staff }) => {
                const staffCounts = staff.data.reduce(
                    (counts, member) => {
                        if (invited.indexOf(member.status) !== -1) {
                            ++counts.invited;
                        } else if (requested.indexOf(member.status) !== -1) {
                            ++counts.requests;
                        } else if (team.indexOf(member.status) !== -1) {
                            ++counts.active;
                        } else {
                            ++counts.other;
                        }
                        return counts;
                    },
                    {
                        active: 0,
                        invited: 0,
                        requests: 0,
                        other: 0,
                    }
                );
                return (
                    <Wrapper>
                        <Link exact to="/">
                            <Icon className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="home" />
                            </Icon>{" "}
                            Home
                        </Link>
                        <Link exact to="/team">
                            <Icon className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="users" />
                            </Icon>{" "}
                            My Team
                        </Link>
                        <Link exact to="/invites">
                            <Icon className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="envelope" />
                                {staffCounts.invited > 0 && (
                                    <Counter className="fa-layers-counter">
                                        {staffCounts.invited}
                                    </Counter>
                                )}
                            </Icon>{" "}
                            Invites
                        </Link>
                        <Link exact to="/requests">
                            <Icon className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="user-check" />
                                {staffCounts.requests > 0 && (
                                    <Counter className="fa-layers-counter">
                                        {staffCounts.requests}
                                    </Counter>
                                )}
                            </Icon>{" "}
                            Requests
                        </Link>
                        {canViewRedemptions && (
                            <Link exact to="/redemption">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="pound-sign" />
                                </Icon>{" "}
                                Redeemed
                            </Link>
                        )}
                        <Link to="/leagues">
                            <Icon className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="trophy" />
                            </Icon>{" "}
                            Leagues
                        </Link>
                        {now.isBefore(videoCompetitionClose) && (
                            <Link to="/vehicle-health-check-competition">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="video" />
                                </Icon>{" "}
                                VHC Videos
                            </Link>
                        )}
                    </Wrapper>
                );
            }}
        </AppContext.Consumer>
    );
};

export default QuickLinks;
